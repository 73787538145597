import { DateTimePeriod, DateTimePeriodOpenTj } from "@brenger/api-client";
import { isSameDay } from "date-fns";

type Dtp = DateTimePeriod | DateTimePeriodOpenTj;

export const dtpsHaveDifferentDays = (dtps: Dtp[]): boolean => {
  // When we have only one dtp for some reason
  if (dtps.length < 2) {
    return false;
  }
  // small util so we can handle openTJ DTP and normal DTP
  const getStartFromDtp = (dtp: Dtp): string => {
    return (dtp as DateTimePeriodOpenTj).period_start || (dtp as DateTimePeriod).start;
  };
  // We don't need to compare all dates, if we just compare against the first it is all we need to know
  const firstStartDate = new Date(getStartFromDtp(dtps[0]));
  // So i starts at one, because we iterate from the second in array
  let i = 1;
  let hasMultipleDates = false;
  do {
    hasMultipleDates = !isSameDay(firstStartDate, new Date(getStartFromDtp(dtps[i])));
    i++;
    // Keep rolling until we run out of DTPs or if we found already a different day
  } while (i < dtps.length && hasMultipleDates === false);

  return hasMultipleDates;
};
