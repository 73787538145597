import * as React from "react";
import cn from "classnames";
import { DateTimePeriodParams } from "@brenger/api-client";
import {} from "@brenger/react";

import { useFormatDate, useFormatTimeframe, useTranslation } from "../../hooks";

interface Props {
  periods: DateTimePeriodParams[];
  isFlexibleDates?: boolean;
}

/**
 *  A summary of datetime periods.
 */
export const DateTimePeriods: React.FC<Props> = (props) => {
  const formatDateFull = useFormatDate("date-full");
  const formatTimeframe = useFormatTimeframe();
  const { t } = useTranslation();
  if (props.isFlexibleDates && props.periods.length > 2) {
    return (
      <div>
        {t((d, template) =>
          template(d.flex_dates.display_date_range, {
            first_date: formatDateFull(props.periods[0].start),
            last_date: formatDateFull(props.periods[props.periods.length - 1].start),
          })
        )}{" "}
        ({formatTimeframe({ start: props.periods[0].start, end: props.periods[0].end })})
      </div>
    );
  }
  return (
    <div className={cn("flex", "flex-col")}>
      {props.periods.map((period, idx) => {
        return (
          <div key={idx} className={cn("capitalize")}>
            {formatDateFull(period.start)} ({formatTimeframe({ start: period.start, end: period.end })})
          </div>
        );
      })}
    </div>
  );
};
