import { DateTimePeriodParams } from "@brenger/api-client";
import { Card, Emphasis } from "@brenger/react";
import { formatKilometers, getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import { DateTimePeriods, MetaItem, ResponsiveContainer } from "..";
import { useTranslation } from "../../hooks";

interface Props {
  id: string;
  details: React.ReactNode;
  title?: React.ReactNode;
  stopNumber: number;
  locality: string;
  dateTimePeriods: DateTimePeriodParams[];
  volume?: React.ReactNode;
  items?: React.ReactNode;
  stopConfirmed: React.ReactNode;
  stopConfirmedAt: React.ReactNode;
  distance?: number;
  instructions?: string;
  isFlexibleDates?: boolean;
}

export const StopCard: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const detailsPageUrl = `${location.pathname}/stops/${getIdFromIri(props.id)}`;

  return (
    <Link to={detailsPageUrl} className="br-text-default">
      <Card className={cn("mb-4", "relative")}>
        <ResponsiveContainer className={cn("justify-between", "items-center")}>
          <MetaItem name={`${t((d) => d.transport_job.stop)} ${props.stopNumber}`} value={props.locality} />
          {props.stopConfirmed}
        </ResponsiveContainer>
        {props.details}
        {props.title}
        {/* The distance of this specific stop - usually only relevant in the context of a bundled job stop.
        In regular jobs, total distance supplants this. */}
        {typeof props.distance === "number" && (
          <div>
            <MetaItem
              name={t((d) => d.transport_job.distance)}
              value={formatKilometers({
                kilometers: props.distance,
                locale: i18n.locale,
              })}
            />
          </div>
        )}
        {props.volume}
        {props.items}
        {props.instructions && (
          <div className={cn("truncate")}>
            <Emphasis>{props.instructions}</Emphasis>
          </div>
        )}
        <ResponsiveContainer className={cn("justify-between", "items-center")}>
          <DateTimePeriods periods={props.dateTimePeriods} isFlexibleDates={props.isFlexibleDates} />
          {/* When available, show confirmation time pill instead of distance. Distance is moot at that stage. */}
          {props.stopConfirmedAt}
        </ResponsiveContainer>
      </Card>
    </Link>
  );
};
