import * as React from "react";

import { DateTimePeriod, ItemSet } from "@brenger/api-client";
import { Emphasis, HorizontalLine, IconStopwatch, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import { useParams } from "react-router-dom";
import {
  AddressDetails,
  DateTimePeriods,
  ImageGallery,
  ItemDetails,
  MetaItem,
  StaticMap,
  StopDetailPills,
  TooltipDressCode,
  UserGeneratedContent,
} from "../../../components";
import { useMapContext, useStopContext, useTranslation } from "../../../hooks";
import { Content, PageHead, Section } from "../../../layout";
import { CacheKey, NewStopParams, Routes, coreClient, dtpsFilterAvailable } from "../../../utils";

export const NewStop: React.FC = () => {
  const params = useParams<NewStopParams>();
  const shortId = params.job_id.slice(-6);
  const { t } = useTranslation();

  const stop = useQuery([CacheKey.RETRIEVE_STOP, params.stop_id], () =>
    coreClient.stops.retrieve({ id: params.stop_id })
  );

  const stopContext = useStopContext(stop.data || null);

  const mapContext = useMapContext({ presentation: "search", tj: stopContext.transportJob, stopId: params.stop_id });
  const stopType = stop.data?.["@type"];

  // The item set for this particular stop.
  const itemSets = (stop.data?.item_sets as ItemSet[] | undefined) || [];

  if (!stop.data || stop.isLoading || stop.isError || !itemSets) {
    const errorFromCore = stop.error;
    const error = errorFromCore ? (errorFromCore as Error)?.message || "Something went wrong" : undefined;
    return (
      <Content
        message={error}
        isLoading={stop.isLoading}
        header={
          <PageHead sectionType="split-details" backUrl={Routes.new.job(params)} title={`Short ID: ${shortId}`} />
        }
      />
    );
  }

  return (
    <Content
      header={<PageHead sectionType="split-details" backUrl={Routes.new.job(params)} title={`Short ID: ${shortId}`} />}
    >
      <Section type="split-details" isTop={true}>
        {/* PICKUP OR DELIVERY */}
        <h5>
          <b className={cn("uppercase")}>
            {t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}
          </b>
        </h5>
        <Spacer h={3} />
        <HorizontalLine type="gray" />
        {/* SHORT ADDRESS */}
        <h4 className={cn("text-blue-500")}>
          <AddressDetails
            address={stopContext.address}
            instructions={stop.data.details.instructions}
            presentation="search"
          />
        </h4>
        <Spacer h={3} />
        {/* AVAILABLE DATETIME*/}
        {stop.data.available_datetime_periods?.length > 0 && (
          <div className={cn("flex", "items-center", "gap-2")}>
            <IconStopwatch className="mt-1" />
            <DateTimePeriods
              periods={
                dtpsFilterAvailable({
                  dtps: stop.data.available_datetime_periods,
                  isFlexibleDates: !!stopContext.transportJob?.is_flexible_dates,
                }) as DateTimePeriod[]
              }
              isFlexibleDates={stopContext.transportJob?.is_flexible_dates}
            />
          </div>
        )}
        {itemSets.flatMap((i) => i.services).includes("safety_shoes_required") && (
          <>
            <Spacer h={2} />
            <TooltipDressCode />
          </>
        )}
        <Spacer h={3} />
        {/* MAP */}
        <StaticMap
          zoom="12"
          markerType="circle"
          markers={mapContext.markers}
          googleMapsUrl={mapContext.googleMapsUrl}
        />
        <Spacer h={8} />
        {/* PICKUP/DELIVERY INFORMATION */}
        <h3>
          {t((d) =>
            stopType === "Pickup"
              ? d.transport_job.headings.pickup_information
              : d.transport_job.headings.delivery_information
          )}
        </h3>
        <Spacer h={3} />
        <HorizontalLine type="gray" />
        <Spacer h={3} />
        {/* DETAILS PILLS */}
        <StopDetailPills details={stop.data.details} itemSets={itemSets} />
        <Spacer h={3} />
        {/* REFERENCE NUMBER */}
        <h5>{t((d) => d.transport_job.id)}</h5>
        <div>{`#${getIdFromIri(stop.data.transport_job)?.slice(-6)}`}</div>
        <Spacer h={3} />
        {/* PICKUP/DELIVERY  */}

        <h5>{t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}</h5>
        {/* Render title and description of all item sets for this stop. */}
        {itemSets.map((itemSet, idx) => {
          return (
            <div key={idx} className={cn("mb-2", "flex", "flex-col")}>
              <div>{itemSet.title}</div>
              {itemSet.description && <div> {itemSet.description}</div>}
              <ItemDetails itemSets={[itemSet]} truncate={false} />
              {itemSet.assembly_description && (
                <MetaItem
                  name={t((d) => d.transport_job.stop_details.services.assembly)}
                  value={<UserGeneratedContent text={itemSet.assembly_description} />}
                />
              )}
            </div>
          );
        })}
        {/* ADDRESS DETAILS */}
        <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
          <h5>{t((d) => d.transport_job.headings.address)}</h5>
        </div>
        <AddressDetails
          address={stopContext.address}
          instructions={stop.data.details.instructions}
          presentation="search"
        />
        <Spacer h={3} />
        {/* STOP CONTACT - DO NOT SURFACE AT THIS STAGE */}
        <h5>{t((d) => d.transport_job.headings.address)}</h5>
        <div>
          <Emphasis>{t((d) => d.search.messages.contact_details)}</Emphasis>
        </div>

        {/* JOB ITEM IMAGES */}
        <Spacer h={3} />
        <ImageGallery itemSets={itemSets} />
      </Section>
    </Content>
  );
};
