import { Button, H2, IconThumbsDown, Spacer, Strong } from "@brenger/react";
import { formatPrice } from "@brenger/utils";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { BundledJobSummary, ImageGallery, RegularJobSummary, StaticMap, TooltipDressCode } from "../../../components";
import { useClaimJobGate, useFeedbackUrl, useMapContext, useTranslation } from "../../../hooks";
import { Content, PageHead, Section } from "../../../layout";
import { CacheKey, NewJobParams, Routes, coreClient } from "../../../utils";
import { clarityTag } from "../../../utils/clarity";

export const NewJob: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const params = useParams<NewJobParams>();
  const shortId = params.job_id.slice(-6);

  // Fetch the transport job
  const tj = useQuery([CacheKey.RETRIEVE_TRANSPORT_JOB, params.job_id], () =>
    coreClient.transportJobs.retrieve({ id: params.job_id })
  );

  // get map context
  const mapContext = useMapContext({ presentation: "search", tj: tj.data });

  // Set up gate to check whether transport job can be claimed
  const ClaimJobGatedUI = useClaimJobGate(tj.data);

  const tjData = tj.data;

  React.useEffect(() => {
    if (tjData?.is_flexible_dates) clarityTag({ flex_dates: "true" });
  }, [tjData?.is_flexible_dates]);

  const feedbackUrl = useFeedbackUrl({ jobId: tjData?.short_id });

  const itemSets = tjData?.item_sets || [];

  if (!tjData || tj.isError || tjData.state === "unbundled") {
    const errorFromCore = tj.error;
    let error = errorFromCore ? (errorFromCore as Error)?.message || "Something went wrong" : undefined;
    if (tj.data?.state === "unbundled") error = t((d) => d.app.errors.general);
    return (
      <Content
        header={<PageHead sectionType="split-details" backUrl={Routes.new.home()} title={`Short ID: ${shortId}`} />}
        messageWrap="split-details"
        message={error}
        isLoading={tj.isLoading}
      />
    );
  }

  return (
    <Content
      header={
        <PageHead
          sectionType="split-details"
          backUrl={Routes.new.home()}
          title={`Short ID: ${shortId}`}
          afterTitle={
            <button
              className={cn("p-2", "ml-4", "border", "rounded-full", "border-gray-800")}
              onClick={feedbackUrl.open}
            >
              <IconThumbsDown className={cn("text-gray-800", "w-5", "h-5")} />
            </button>
          }
        />
      }
      footer={
        !ClaimJobGatedUI && (
          <Section type="split-details">
            <div className={cn("flex", "items-center")}>
              <div className={cn("ml-2", "w-full")}>
                <Button
                  size="lg"
                  className={cn("w-full")}
                  buttonType="secondary"
                  onClick={() => history.push(Routes.new.claim(params), "forward")}
                >
                  <span>
                    <Strong className={cn("mr-1")}>{t((d) => d.transport_job.actions.claim_job_non_guaranteed)}</Strong>
                    <Strong>
                      {formatPrice({
                        amount: tjData.total_payout.excl_vat.amount,
                        currency: tjData.total_payout.excl_vat.currency,
                        locale: i18n.locale,
                      })}
                    </Strong>
                  </span>
                </Button>
              </div>
            </div>
          </Section>
        )
      }
    >
      <Section type="split-details" isTop={true}>
        {!!ClaimJobGatedUI && <div className={"mb-3"}>{ClaimJobGatedUI}</div>}
        <div className={cn("flex", "justify-between", "items-start")}>
          <H2 className={cn("capitalize", "truncate")}>{tjData.title}</H2>
        </div>
        <Spacer h={3} />
        <StaticMap markers={mapContext.markers} points={mapContext.points} googleMapsUrl={mapContext.googleMapsUrl} />
        {itemSets.flatMap((i) => i.services).includes("safety_shoes_required") && (
          <>
            <Spacer h={4} />
            <TooltipDressCode />
          </>
        )}
        <Spacer h={2} />
        {tjData.bundled ? (
          <BundledJobSummary tj={tjData} presentation="search" />
        ) : (
          <RegularJobSummary tj={tjData} presentation="search" />
        )}
        <ImageGallery itemSets={itemSets} />
      </Section>
    </Content>
  );
};
