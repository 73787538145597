import { DateTimePeriodOpenTj } from "@brenger/api-client";
import { isSameDay, parseISO } from "date-fns";

export interface DayPaginationArgs {
  dtps: DateTimePeriodOpenTj[];
  current?: DateTimePeriodOpenTj;
}

export interface DayPagination {
  next: boolean;
  prev: boolean;
  indexCurrent: number;
  dotsCount: number;
}

export const getDayPagination = ({ dtps, current }: DayPaginationArgs): DayPagination | null => {
  const totalDtps = dtps.length;

  // Not enough DTPs to make sense or no current DTP present
  if (totalDtps < 2 || !current) {
    return null;
  }

  // We find the index of current DTP by comparing the day to the other DTPs
  let indexCurrent = dtps.findIndex((dtp) => isSameDay(parseISO(dtp.period_start), parseISO(current.period_start)));

  // If there less then 5 DTPs, we simple show them all
  if (totalDtps < 5) {
    return {
      next: false,
      prev: false,
      indexCurrent,
      dotsCount: totalDtps,
    };
  }

  // In all other cases we show a shortend version
  // We have three options: first, center or right
  const isLeft = indexCurrent < 3;
  const isRight = indexCurrent > totalDtps - 4;
  const isCenter = !isLeft && !isRight;

  // In case of Left: we don't need to adjust indexCurrent
  // O O 0 O >

  // In case of Center: the active dot is always the center, with three dots that is index = 1
  // < O 0 O >
  if (isCenter) indexCurrent = 1;

  // In case of Right we need to deduct the total and amount of dots to match current index with the index of the dots
  // < O 0 O O
  if (isRight) indexCurrent = indexCurrent - (totalDtps - 4);

  return {
    next: isLeft || isCenter,
    prev: isRight || isCenter,
    indexCurrent,
    dotsCount: isCenter ? 3 : 4,
  };
};
