type ClarityFlexDatesTag = Partial<Record<"flex_dates", "true">>;

type ClarityTag = ClarityFlexDatesTag;

declare global {
  function clarity(action: "set", key: string, value: string): void;
}

export const clarityTag = (tag: ClarityTag): void => {
  if (typeof clarity !== "function") return;
  for (const [key, value] of Object.entries(tag)) {
    clarity("set", key, value as string);
  }
};
