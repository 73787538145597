/**
 * BIG FAT NOTE: THIS FILE IS AN EXACT COPY OF PLANNING STOP DETAILS
 * - WE HAVEN'T DECIDED YET HOW TO MERGE PLANNING AND DAYROUTES
 * - DAYROUTE COULD REPLACE PLANNING SECTION IN THE FUTURE
 * - DECIDED TO KEEP BOTH UNTANGLED SO THAT THEY COULD EASILY LIVE NEXT TO EACH OTHER UNTIL
 * THERE IS A WINNER OR OTHER STRATEGY WHERE THEY BOTH SERVE A DIFF GOAL
 * - KEEP THEM BOTH IN SYNC, AS IN SAME FUNCTIONALITIES, UNTIL THAT POINT
 */

import { DayRouteActivity, ItemSet } from "@brenger/api-client";
import {
  Button,
  Card,
  HorizontalLine,
  IconEditPencil,
  IconHorizontalDots,
  IconLiveTimeFrame,
  IconWhatsapp,
  Message,
  Spacer,
  useModalState,
} from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useHistory, useParams } from "react-router";

import {
  AddressDetails,
  ClientReferences,
  ConfirmationSignatures,
  ContactDetails,
  ExternalInvoices,
  FormatCommitedStopTime,
  ImageGallery,
  ItemDetails,
  ItemSetAttributes,
  MetaItem,
  PhotoProofs,
  StaticMap,
  StopConfirmedAtPill,
  StopDetailPills,
  TooltipDressCode,
  UserGeneratedContent,
} from "../../../components";
import {
  createGoogleMapsLinkFromSingleAddress,
  useAuth,
  useCanConfirmStop,
  useFormatDate,
  useStopContext,
  useTranslation,
} from "../../../hooks";
import {
  CacheKey,
  coreClient,
  DayRouteStopParams,
  getStopContactActions,
  getStopOnTimeActions,
  getStopRunningLateActions,
  Routes,
} from "../../../utils";
import { DayRouteEditBrengerStop } from "../../edit/DayRoute/DayRouteEditBrengerStop";
import { ActionSheetModal, ActionSheetModalOption, Content, PageHead, Section } from "../../../layout";
import { DayRouteStopStickyFooter } from "./DayRouteStopStickyFooter";

interface Props {
  activity?: DayRouteActivity | null;
  humanIndex?: number;
}

export const DayRouteNormalStopDetails: React.FC<Props> = ({ activity, humanIndex }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();
  const formatHourMinute = useFormatDate("hour-minute");

  // A day route activity, that is a brenger job has either a pickup or a delivery iri
  const stopId = getIdFromIri(activity?.pickup || activity?.delivery) || "";
  const stop = useQuery([CacheKey.RETRIEVE_STOP, stopId], () => coreClient.stops.retrieve({ id: stopId }));
  const params = useParams<DayRouteStopParams>();
  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );

  const itemSets = stop.data?.item_sets as ItemSet[] | undefined;
  const stopContext = useStopContext(stop.data || null);
  const { canConfirmStop, cannotConfirmStopReason, messageType } = useCanConfirmStop({
    iri: stop.data?.["@id"],
    showSuccessMessage: false,
  });

  const [actions, setActions] = React.useState<{
    header: string;
    options: ActionSheetModalOption[];
  } | null>(null);
  const actionModal = useModalState();
  const editModal = useModalState();

  if (!stop.data || stop.error || !itemSets) {
    return <Content message={(stop.error as Error)?.message} messageWrap="split-details" />;
  }

  const stopType = stop.data["@type"];
  const { phone, email } = stopContext.contact || {};
  const relatedDayRouteStop = dayRoute.data?.day_route_activities?.find((a) => {
    if (stopType === "Pickup") {
      return getIdFromIri(a.delivery) === stopContext.relatedStopId;
    }
    if (stopType === "Delivery") {
      return getIdFromIri(a.pickup) === stopContext.relatedStopId;
    }
    return false;
  });
  /**
   * Set up shared action sheet items
   */
  const runningLateActions = getStopRunningLateActions({
    phone,
    driverName: auth.user?.first_name,
    t,
  });
  const onTimeActions = getStopOnTimeActions({
    phone,
    driverName: auth.user?.first_name,
    t,
  });
  const contactOptions = getStopContactActions({ phone, email, t });
  /**
   * Set up Action Sheet items related to the Address
   */
  const googleMapsUrl = activity?.address ? createGoogleMapsLinkFromSingleAddress(activity.address) : "";
  const addressActions: ActionSheetModalOption[] = [
    {
      title: t((d) => d.planning.actions.open_google_maps),
      onClick: () => window.open(googleMapsUrl, "_blank"),
    },
  ];
  if (relatedDayRouteStop) {
    addressActions.push({
      // Related stop link (pick -> delivery, vice versa)
      title: t((d) => d.transport_job.actions[stopType === "Pickup" ? "view_delivery" : "view_pickup"]),
      onClick: () =>
        history.push(
          Routes.dayroutes.stop({ ...params, stop_id: getIdFromIri(relatedDayRouteStop?.["@id"]) as string })
        ),
    });
  }

  return (
    <>
      <Content
        isLoading={!activity}
        header={
          <PageHead
            sectionType="split-details"
            backUrl={Routes.dayroutes.details(params)}
            title={`Stop: ${humanIndex}`}
            afterTitle={
              <div className={cn("text-blue-600", "flex", "items-center", "cursor-pointer")} onClick={editModal.open}>
                <IconEditPencil className={cn("mr-1")} /> {t((d) => d.actions.edit)}
              </div>
            }
          />
        }
        footer={
          activity ? (
            <Section type="split-details">
              <DayRouteStopStickyFooter activity={activity} canConfirm={canConfirmStop} />
            </Section>
          ) : undefined
        }
      >
        <Section type={"split-details"} isTop={true}>
          {/* PICKUP OR DELIVERY */}
          <div className={cn("flex", "items-center", "justify-between")}>
            <h6 className={cn("uppercase")}>
              {t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}
            </h6>
          </div>
          <Spacer h={3} />
          <HorizontalLine type="gray" />
          {/* SHORT ADDRESS */}
          <Spacer h={3} />
          <AddressDetails address={stopContext.address} presentation="dayroute-details" />
          <Spacer h={3} />
          {/* COMMITED DATETIME & SERVICE TIME */}
          <div className={cn("flex", "items-center")}>
            {activity?.committed_date_time_period && (
              <FormatCommitedStopTime
                textClassname={["mr-2"]}
                start={activity?.committed_date_time_period.start}
                end={activity?.committed_date_time_period.end}
              />
            )}
            {activity?.service_time_seconds && (
              <>
                <div>
                  {t((d) => d.transport_job.service_time)}: {Number(activity?.service_time_seconds / 60).toFixed()}
                </div>
                <div className={cn("m-1")}> {t((d) => d.calendar.minute_full)}</div>
              </>
            )}
          </div>
          <Spacer h={2} />
          <div>
            <StopConfirmedAtPill
              stopType={stopType}
              itemSets={itemSets}
              // Use the "last" commitment in the list (in the rare case where the driver has more than one)
              committedDtp={stopContext.committedDtp}
            />
          </div>
          {!canConfirmStop && cannotConfirmStopReason && (
            <Message type={messageType} className={cn("my-4")}>
              {cannotConfirmStopReason}
            </Message>
          )}

          {/* This container holds the running late buttons */}
          {phone && canConfirmStop && (
            <Card className={cn("mt-2", "no-print", "!bg-color-light-blue")}>
              {activity?.eta_window ? (
                <div className={cn("flex", "items-center")}>
                  <IconLiveTimeFrame className={cn("m-1")} />
                  <b>{t((d) => d.day_route.track_and_trace)}</b>
                  <Spacer w={1} />
                  <b>
                    {formatHourMinute(activity?.eta_window.start)} - {formatHourMinute(activity?.eta_window.end)}
                  </b>
                </div>
              ) : (
                <div>{t((d) => d.planning.running_late.desc)}</div>
              )}

              <div className={cn("flex", "mt-3")}>
                <Button
                  size="md"
                  icon={<IconWhatsapp />}
                  className={cn("w-full", "whitespace-nowrap")}
                  iconPos="left"
                  onClick={() => {
                    setActions({
                      header: t((d) => d.planning.on_time.title),
                      options: onTimeActions,
                    });
                    actionModal.open();
                  }}
                >
                  {t((d) => d.planning.on_time.cta)}
                </Button>
                <Spacer w={2} />
                <Button
                  size="md"
                  icon={<IconWhatsapp />}
                  className={cn("w-full", "whitespace-nowrap")}
                  iconPos="left"
                  onClick={() => {
                    setActions({
                      header: t((d) => d.planning.running_late.title),
                      options: runningLateActions,
                    });
                    actionModal.open();
                  }}
                >
                  {t((d) => d.planning.running_late.cta)}
                </Button>
              </div>
            </Card>
          )}

          {itemSets.flatMap((i) => i.services).includes("safety_shoes_required") && (
            <>
              <Spacer h={2} />
              <TooltipDressCode />
            </>
          )}
          <Spacer h={3} />
          <StaticMap
            zoom="15"
            markers={[
              {
                location: {
                  lat: activity?.address?.lat || 0,
                  lng: activity?.address?.lng || 0,
                },
                stopNumber: String(humanIndex),
              },
            ]}
            googleMapsUrl={googleMapsUrl}
          />
          <Spacer h={8} />
          <h4>
            {t((d) =>
              stopType === "Pickup"
                ? d.transport_job.headings.pickup_information
                : d.transport_job.headings.delivery_information
            )}
          </h4>
          <Spacer h={3} />
          <HorizontalLine type="gray" />
          <Spacer h={3} />
          <StopDetailPills details={stop.data.details} itemSets={itemSets} />
          <Spacer h={3} />
          <h6>{t((d) => d.transport_job.id)}</h6>
          <div>{`#${getIdFromIri(stop.data.transport_job)?.slice(-6)}`}</div>
          <Spacer h={3} />
          <h6>{t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}</h6>
          {/* TITLE & DESCRIPTION OF EACH ITEM SET FOR THIS STOP */}
          {itemSets.map((itemSet, idx) => {
            return (
              <div className={cn("mb-2", "flex", "flex-col")} key={idx}>
                <div>{itemSet.title}</div>
                {itemSet.description && <div> {itemSet.description}</div>}
                {itemSet.assembly_description && (
                  <MetaItem
                    name={t((d) => d.transport_job.stop_details.services.assembly)}
                    value={<UserGeneratedContent text={itemSet.assembly_description} />}
                  />
                )}
                <ClientReferences itemSets={[itemSet]} />
                <ItemSetAttributes itemSets={[itemSet]} />
                <ItemDetails itemSets={[itemSet]} />
                <ExternalInvoices itemSets={[itemSet]} />
              </div>
            );
          })}
          {/* ADDRESS DETAILS + ASSOCIATED ACTIONS */}
          <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
            <h6>{t((d) => d.transport_job.headings.address)}</h6>
            <button
              className={cn("px-1", "border", "rounded-full", "border-gray-800")}
              onClick={() => {
                setActions({
                  header: t((d) => d.actions.more),
                  options: addressActions,
                });
                actionModal.open();
              }}
            >
              <IconHorizontalDots />
            </button>
          </div>
          <AddressDetails
            address={stopContext.address}
            instructions={stop.data.details.instructions}
            presentation="planning"
          />
          {/* NOT EVERY STOP HAS A CONTACT */}
          {stopContext.contact && (
            <>
              <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
                <h6>{t((d) => d.transport_job.headings.contact)}</h6>
                <button
                  className={cn("px-1", "border", "rounded-full", "border-gray-800")}
                  onClick={() => {
                    setActions({
                      header: t((d) => d.actions.more),
                      options: contactOptions,
                    });
                    actionModal.open();
                  }}
                >
                  <IconHorizontalDots />
                </button>
              </div>
              <ContactDetails contact={stopContext.contact} companyName={stopContext.companyName} />
            </>
          )}
          {/* SIGNATURES */}
          <Spacer h={3} />
          <h6>{t((d) => d.transport_job.headings.signatures)}</h6>
          <ConfirmationSignatures
            itemSets={itemSets}
            stopType={stopType}
            emptyStateText={t((d) => d.app.empty_state.generic)}
          />
          {/* PHOTO PROOFS */}
          <Spacer h={3} />
          <h6>{t((d) => d.transport_job.headings.photo_proofs)}</h6>
          <PhotoProofs itemSets={itemSets} stopType={stopType} emptyStateText={t((d) => d.app.empty_state.generic)} />
          {/* JOB ITEM IMAGES */}
          <Spacer h={3} />
          <ImageGallery itemSets={itemSets} />
          <Spacer h={3} />
        </Section>
      </Content>
      {activity && (
        <DayRouteEditBrengerStop activity={activity} isActive={editModal.isActive} closeHandler={editModal.close} />
      )}
      <ActionSheetModal {...actions} isOpen={actionModal.isActive} close={actionModal.close} />
    </>
  );
};
