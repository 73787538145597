import { Address, Stop } from "@brenger/api-client";
import { Pill, Timeline } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useTranslation } from "../../hooks";

interface Props {
  tjId?: string;
  isPickup?: boolean;
  pickup?: Stop;
  delivery?: Stop;
}

export const RouteOverViewNormal: React.FC<Props> = ({ tjId, isPickup, pickup, delivery }) => {
  const { t } = useTranslation();
  if (!pickup || !delivery) {
    return null;
  }
  return (
    <div className={cn(["bg-gray-100", "p-4"])}>
      <Pill type="blue">
        {t((d) => d.transport_job.single_job)}: {tjId}
      </Pill>
      <Timeline isFirst={true} isLast={false}>
        <div className={cn({ "font-bold": isPickup === true })}>
          {(pickup.address as Address).line1}, {(pickup.address as Address).locality}
        </div>
      </Timeline>
      <Timeline isFirst={false} isLast={true}>
        <div className={cn({ "font-bold": isPickup === false })}>
          {(delivery.address as Address).line1}, {(pickup.address as Address).locality}
        </div>
      </Timeline>
    </div>
  );
};
