import {
  CoreClient,
  parseErrorMessageFromCore,
  RoutePlannerClient,
  SandboxToken,
  UserDataClient,
} from "@brenger/api-client";
import axios from "axios";
import { Config } from "../config";
import { logger } from "./logger";
import { Routes } from "./routes";

// Use these cache keys to set network responses in an in-memory cache
// See useQuery for implementation details.

/**
 * Cache keys are used by React-Query for building up a cache of network responses.
 * To have less side effects when emptying the cache, we categorize the cache with prefixes.
 * This way we can empty cache more responsibly and trigger less refreshes.
 *
 * See useCache() hook how we make use of it
 */

/**
 * Functional data
 * - View data / Job data
 * - Data that is stored based on app use
 * - can be flushed with no consequences
 */
export const CachePrefixFunc = "FUNC";
/**
 * Auth data
 * - Not something to flush that often
 * - User data about the logged in user
 */
export const CachePrefixAuth = "AUTH";
/**
 * Meta data
 * - Not really important, not job or user related
 * - Data that is not likely to change frequently
 */
export const CachePrefixConfig = "CONFIG";

export type CachePrefix = typeof CachePrefixFunc | typeof CachePrefixAuth | typeof CachePrefixConfig;

export enum CacheKey {
  USER_LOCATIONS = `${CachePrefixFunc}_USER_LOCATIONS`,
  LIST_MESSAGES_USER_AND_TJAL = `${CachePrefixFunc}_LIST_MESSAGES_USER_AND_TJAL`,
  LIST_TRANSPORT_JOBS = `${CachePrefixFunc}_LIST_TRANSPORT_JOBS`,
  LIST_TRANSPORT_JOBS_SCORES = `${CachePrefixFunc}_LIST_TRANSPORT_JOBS_SCORES`,
  LIST_PLANNING_TRANSPORT_JOB_ACCOUNT_LINKS = `${CachePrefixFunc}_LIST_PLANNING_TRANSPORT_JOB_ACCOUNT_LINKS`,
  LIST_DELIVERED_TRANSPORT_JOB_ACCOUNT_LINKS = `${CachePrefixFunc}_LIST_DELIVERED_TRANSPORT_JOB_ACCOUNT_LINKS`,
  LIST_TJALS = `${CachePrefixFunc}_LIST_TRANSPORTS`,
  RETRIEVE_TRANSPORT_JOB = `${CachePrefixFunc}_RETRIEVE_TRANSPORT_JOB`,
  RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK = `${CachePrefixFunc}_RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK`,
  RETRIEVE_CURRENT_USER = `${CachePrefixAuth}_RETRIEVE_CURRENT_USER`,
  RETRIEVE_USER = `${CachePrefixFunc}_RETRIEVE_USER`,
  RETRIEVE_STOP = `${CachePrefixFunc}_RETRIEVE_STOP`,
  RETRIEVE_ROUTE = `${CachePrefixFunc}_RETRIEVE_ROUTE`,
  RETRIEVE_DAY_ROUTE = `${CachePrefixFunc}_RETRIEVE_DAY_ROUTE`,
  RETRIEVE_DAY_ROUTE_LEGS = `${CachePrefixFunc}_RETRIEVE_DAY_ROUTE_LEGS`,
  RETRIEVE_PROPOSED_DAY_ROUTE = `${CachePrefixFunc}_RETRIEVE_PROPOSED_DAY_ROUTE`,
  RETRIEVE_PREFERRED_ROUTES = `${CachePrefixFunc}_RETRIEVE_PREFERRED_ROUTES`,
  RETRIEVE_CONTACT = `${CachePrefixFunc}_RETRIEVE_CONTACT`,
  RETRIEVE_PRODUCT_PAYMENT = `${CachePrefixFunc}_RETRIEVE_PRODUCT_PAYMENT`,
  TRANSLATIONS = `${CachePrefixConfig}_TRANSLATIONS`,
  INBOX_LIST = `${CachePrefixFunc}_INBOX_LIST`,
  INBOX_DETAILS = `${CachePrefixFunc}_INBOX_DETAILS`,
  ACCOUNT_USERS = `${CachePrefixFunc}_ACCOUNT_USERS`,
  COUNTRY_CODE = `${CachePrefixFunc}_COUNTRY_CODE`,
  APP_VERSION = `${CachePrefixConfig}_APP_VERSION`,
  RETRIEVE_VEHICLE_LIST = `${CachePrefixFunc}_RETRIEVE_VEHICLE_LIST`,
  RETRIEVE_SUMMARY = `${CachePrefixFunc}_RETRIEVE_SUMMARY`,
  RETRIEVE_ACCOUNT = `${CachePrefixFunc}_RETRIEVE_ACCOUNT`,
  RETRIEVE_USERS = `${CachePrefixFunc}_RETRIEVE_USERS`,
  RETRIEVE_ADDRESS = `${CachePrefixFunc}_RETRIEVE_ADDRESS`,
  RETRIEVE_DASHBOARD = `${CachePrefixFunc}_RETRIEVE_DASHBOARD`,
  RETRIEVE_SIGNATURE = `${CachePrefixFunc}_RETRIEVE_SIGNATURE`,
  RETRIEVE_PHOTO_PROOF = `${CachePrefixFunc}_RETRIEVE_PHOTO_PROOF`,
  RETRIEVE_GEO_AUTOCOMPLETE = `${CachePrefixFunc}_RETRIEVE_GEO_AUTOCOMPLETE`,
  RETRIEVE_GEO_LOCATION_DETAILS = `${CachePrefixFunc}_RETRIEVE_GEO_LOCATION_DETAILS`,
  RETRIEVE_GEO_STOP_PROXIMITY = `${CachePrefixFunc}_RETRIEVE_GEO_STOP_PROXIMITY`,
  RETRIEVE_CONFIRMATION_RULES = `${CachePrefixFunc}_RETRIEVE_CONFIRMATION_RULES`,
  RETRIEVE_BANK_ACCOUNT = `${CachePrefixFunc}_RETRIEVE_BANK_ACCOUNT`,
  RETRIEVE_REVIEWS = `${CachePrefixFunc}_RETRIEVE_REVIEWS`,
}

export enum PersistedStorageKey {
  HAS_SEEN_VAT_NOTIFICATION = "HAS_SEEN_VAT_NOTIFICATION",
  HAS_SEEN_DAY_ROUTE_NOTIFICATION = "HAS_SEEN_DAY_ROUTE_NOTIFICATION",
  HAS_SEEN_WEB_PROMPT_NOTIFICATION = "HAS_SEEN_WEB_PROMPT_NOTIFICATION",
  HAS_SEEN_WELCOME_TOUR = "HAS_SEEN_WELCOME_TOUR",
  NEXT_NPS_DATE = "NEXT_NPS_DATE",
  HAS_SEEN_DAY_DRESSCODE = "HAS_SEEN_DAY_DRESSCODE",
  HAS_SEEN_OPTIONAL_APP_UPDATE = "HAS_SEEN_OPTIONAL_APP_UPDATE",
  IS_MENU_EXPANDED = "IS_MENU_EXPANDED",
  BRENGER_LIST_JOB_SCORE = "BRENGER_LIST_JOB_SCORE",
}

// Some standard TTL to use throughout app
export enum StaleTTL {
  /**
   * 1 minute.
   */
  XS = 60000,
  /**
   * 2 minutes.
   */
  SM = 120000,
  /**
   * 5 minutes.
   */
  MD = 300000,
  /**
   * 10 minutes.
   */
  LG = 600000,
  /**
   * 60 minutes.
   */
  XL = 3600000,
}

interface InRangeParams {
  val?: number;
  min: number;
  max: number;
  ignore?: number[];
}

export const isValueInRange = ({ val, min, max, ignore }: InRangeParams): boolean => {
  return typeof val === "number" && val >= min && val <= max && !ignore?.includes(val);
};

/**
 * NOTE: This is not in React-land, so we cannot utilize context-aware translations easily. Therefore,
 * default to Dutch erro essage. English version below for the future.
 * "It seems this is taking too long to process. That could either be a problem with your connection or our systems. Please try again shortly."
 */
const timeoutErrorMessage =
  "Het lijkt erop dat het te lang duurt om dit te verwerken. Dat kan aan een probleem met je verbinding liggen of onze systemen. Probeer het zo opnieuw.";

const coreAxiosInstance = axios.create({
  baseURL: Config.API_URL,
  withCredentials: true,
  // A 30s global timeout for network requests to core.
  // By default, axios does not have a timeout. This was added to avoid loading photo proofs endlessly.
  timeout: 30000,
  timeoutErrorMessage,
  headers: {
    Accept: "application/ld+json",
    "Content-Type": "application/ld+json",
  },
});

coreAxiosInstance.interceptors.request.use(undefined, (error) => {
  const message = error?.message || "unknown axios request error";
  const details = { ...error.request };
  logger.error(message, details);

  return Promise.reject(error);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRejectedFromCore = (error: any): Promise<Error> => {
  const details = { ...error?.response, ...error?.request };
  const statusCode = error?.response?.status || 0;
  if (statusCode === "403" && !window.location.pathname.includes("login")) {
    window.location.assign(Routes.auth.login());
  }
  // Parse the error message from core.
  const errorMessage = parseErrorMessageFromCore(error);

  const shouldLogError = isValueInRange({ val: statusCode, min: 400, max: 499, ignore: [403, 404] });

  // Check if we should if ignore the error message
  const isIgnoredMessaged = (message: string): boolean =>
    ["Transport job already has an accepted offer.", "Max number of offers reached."].some((ignore: string) =>
      message.includes(ignore)
    );
  // If this error is associated with a status code we care about and shouldn't be ignored
  // log the message + details to sentry.
  if (shouldLogError && !isIgnoredMessaged(errorMessage)) logger.error(errorMessage, details);
  // Put the parsed error message into a new error object so easily access it downstream on the "message" key.
  const newError = new Error(errorMessage);

  return Promise.reject(newError);
};

coreAxiosInstance.interceptors.response.use(undefined, onRejectedFromCore);

export const coreClient = new CoreClient(coreAxiosInstance);

const routePlannercoreAxiosInstance = axios.create({
  baseURL: Config.ROUTE_PLANNER_URL,
});

export const routePlannerClient = new RoutePlannerClient(routePlannercoreAxiosInstance);

export const userDataClient = new UserDataClient(Config.USER_DATA_URL || "");

export const isSandbox = (): boolean => {
  return true;
  return window.location.host.includes("sandbox");
};

export const fetchSandboxToken = async (creds: { email: string; password: string }): Promise<SandboxToken | null> => {
  const prodClient = new CoreClient(
    axios.create({
      // NOTE: We need prod as gateway to sandbox, it will deliver as the read token for sandbox
      baseURL: `https://api.brenger.nl`,
      withCredentials: true,
      headers: {
        Accept: "application/ld+json",
        "Content-Type": "application/ld+json",
      },
    })
  );
  try {
    // login at production first
    await prodClient.users.login(creds);
    // Then retrieve the token also from prod
    const tokenResp = await prodClient.users.retrieveSandboxToken();
    return tokenResp;
  } catch (error) {
    logger.error("Error fetching sandbox token", error);
    return null;
  }
};
