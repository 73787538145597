import { Stop, Route, TransportJob } from "@brenger/api-client";

export const getOrderedStopsFromTj = (tj?: TransportJob, route?: Route | null): Stop[] => {
  if (!tj) return [];

  const allStops = [...tj.pickups, ...tj.deliveries];

  if (tj && !tj.bundled) return allStops;

  if (tj.bundled && !route) return allStops;

  const orderedRouteStopIds = [...(route?.stops || [])]
    .sort((a) => {
      return a.index;
    })
    .map((routeStop) => {
      return routeStop.pickup || routeStop.delivery;
    });

  return orderedRouteStopIds
    .map((stopId) => {
      // We can safely assume there is a Pickup or Delivery because we filter out
      // undefined cases next!
      return allStops.find((stop) => stop["@id"] === stopId) as Stop;
    })
    .filter(Boolean);
};
