import cn from "classnames";
import { DayPaginationArgs, getDayPagination } from "./getDayPagination";

const clsArrow = "border-t-2 border-t-transparent border-b-2 border-b-transparent";

export const DayPagination: React.FC<DayPaginationArgs> = (props) => {
  const p = getDayPagination(props);

  if (p === null) return null;

  return (
    <div className={"absolute bottom-2 right-2 flex justify-end gap-1 items-center"}>
      {p.prev && <div className={cn(clsArrow, "border-r-4 border-r-white")} />}
      {Array.from({ length: p.dotsCount }, (_, i) => i).map((index) => {
        const isCurrent = index === p.indexCurrent;
        return (
          <div
            key={index}
            className={cn("rounded-full w-[.35rem] h-[.35rem] border", {
              "bg-black border-black": isCurrent,
              "bg-white border-white": !isCurrent,
            })}
          />
        );
      })}
      {p.next && <div className={cn(clsArrow, "border-l-4 border-l-white")} />}
    </div>
  );
};
