import { HorizontalLine, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { AddressDetails } from "../../../components";
import { useAuth, useCache, useFormatTimeframe, useStopContext, useTranslation } from "../../../hooks";
import { CacheKey, Routes, TransportsStopParams, coreClient } from "../../../utils";
import { SheetModal } from "../../../layout";
import { CancelOrRevoke } from "../CancelOrRevoke";
import { EditCommittedDtp } from "../EditCommittedDtp";
import { EditDriver } from "../EditDriver";
import { NotAllowed } from "../NotAllowed";
import { RouteOverViewBundled } from "../RouteOverviewBundled";
import { RouteOverViewNormal } from "../RouteOverviewNormal";
import { isSameDay, parseISO } from "date-fns";

export const TransportJobEditStop: React.FC<{ isActive: boolean; closeHandler(): void }> = (props) => {
  const { t } = useTranslation();
  const params = useParams<TransportsStopParams>();
  const formatDTP = useFormatTimeframe();
  const auth = useAuth();

  const stop = useQuery([CacheKey.RETRIEVE_STOP, params.stop_id], () =>
    coreClient.stops.retrieve({ id: params.stop_id })
  );
  const stopContext = useStopContext(stop.data || null, params.job_id);

  const cache = useCache();
  const onSuccessDTPupdate = (): void => cache.clear();

  // Initialize the driverUser with the current one from the TJAL
  const stopType = stop.data?.["@type"];
  const isBundled = stopContext.transportJob?.bundled;

  const committedDtp = stopContext.committedDtp;
  // Once commited the courier is not able to switch days, he can propose a diff time slot
  // So we filter available DTPs by commited one
  const matchedAvailableDtp = committedDtp?.start
    ? stop.data?.available_datetime_periods.find((dtp) => isSameDay(parseISO(dtp.start), parseISO(committedDtp?.start)))
    : undefined;

  if (!auth.isAccountAdmin) {
    return (
      <SheetModal
        header={t((d) => d.stop_edit.headings.change_cancel_stop)}
        isLoading={stopContext.loading}
        isOpen={props.isActive}
        close={props.closeHandler}
      >
        <NotAllowed />
      </SheetModal>
    );
  }
  return (
    <SheetModal
      header={t((d) => d.stop_edit.headings.change_cancel_stop)}
      isLoading={stopContext.loading}
      isOpen={props.isActive}
      close={props.closeHandler}
    >
      <h6 className={cn("flex", "justify-between", "items-center", "uppercase")}>
        {t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}
      </h6>
      <HorizontalLine type="gray" className={cn("my-2")} />
      <div>
        <b>{t((d) => d.transport_job.headings.address)}</b>
      </div>
      <AddressDetails
        presentation={"planning-stop-edit"}
        address={stopContext.address}
        instructions={stop.data?.details.instructions}
      />
      {matchedAvailableDtp && (
        <>
          <Spacer h={2} />
          <div>
            <b>{t((d) => d.stop_edit.headings.available_dtp)}</b>
          </div>
          <div>{formatDTP(matchedAvailableDtp)}</div>
          <Spacer h={2} />
          <EditCommittedDtp
            checkDisabled={true}
            availableDtps={[matchedAvailableDtp]}
            committedDtp={committedDtp || null}
            onSuccess={onSuccessDTPupdate}
          />
        </>
      )}
      <Spacer h={4} />
      <h5>{t((d) => d.stop_edit.headings[isBundled ? "change_bundle" : "change_single_job"])}</h5>
      <HorizontalLine type="gray" className={cn("my-2")} />
      <Spacer h={1} />
      {!isBundled && (
        <RouteOverViewNormal
          tjId={stopContext.transportJob?.short_id}
          isPickup={stopType === "Pickup"}
          pickup={stopContext.transportJob?.pickups[0]}
          delivery={stopContext.transportJob?.deliveries[0]}
        />
      )}
      {isBundled && (
        <RouteOverViewBundled
          tjId={stopContext.transportJob?.short_id}
          activeStop={stop.data || null}
          route={stopContext.route?.stops}
          tj={stopContext.transportJob}
        />
      )}
      <Spacer h={2} />
      <EditDriver tjalId={params.job_id} successPath={Routes.transports.stop(params)} />
      <Spacer h={4} />
      <CancelOrRevoke
        tjalId={params.job_id}
        isBundled={stopContext.transportJob?.bundled}
        isDirectClaimable={stopContext.transportJob?.directly_claimable}
        successPath={Routes.transports.home(params)}
      />
    </SheetModal>
  );
};
