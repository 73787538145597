import { DateTimePeriodOpenTj } from "@brenger/api-client";
import { Card } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { TransportJobDetails } from "../../../components";
import {
  JobFunnelEvent,
  OpenTjExtended,
  useAuth,
  useFormatDate,
  useFormatTimeframe,
  useIntersection,
  useTracking,
} from "../../../hooks";
import { SortByField } from "../../../store/search/types";
import {
  PersistedStorageKey,
  Routes,
  dtpsFilterAvailable,
  dtpsHaveDifferentDays,
  formatLocality,
  formatSecondsToHoursMinutes,
  isComboJob,
  logger,
} from "../../../utils";

import { DayPagination } from "./DayPagination";
import "./listItem.scss";

/**
 * In some cases, such as the Preview page, we do not show the nav.
 */
interface Props {
  openTJ: OpenTjExtended;
  index: number;
  totalJobsCount: number;
  filteredJobsCount: number;
  // It is only a string because we need it here for the analytics event
  activeFilter: string;
  sortByField: SortByField;
  isActive: boolean;
}

export const NewListItem: React.FC<Props> = ({
  openTJ,
  index,
  totalJobsCount,
  filteredJobsCount,
  activeFilter,
  sortByField,
  isActive,
}) => {
  const formatDateFull = useFormatDate("date-full");
  const formatTimeframe = useFormatTimeframe();
  const intersectionRef = React.useRef(null);
  const isIntersecting = useIntersection(intersectionRef);
  const tracking = useTracking();
  const auth = useAuth();
  const jobId = getIdFromIri(openTJ["@id"]) || "";
  const [isItemShown, setIsItemShown] = React.useState(false);
  const history = useHistory();
  const trackingEvent: JobFunnelEvent = {
    event: "Job funnel",
    // In UTC
    timeStamp: new Date(new Date().toUTCString()).toISOString(),
    action: "impression",
    id: jobId,
    listPosition: index + 1,
    listLength: filteredJobsCount,
    score: openTJ.score ? openTJ.score?.value : null,
    isDefaultScore: openTJ?.score ? openTJ.score.isDefaultScore : null,
    totalJobsCount,
    activeFilter,
    sortByField,
    courierId: auth.userId || null,
  };
  React.useEffect(() => {
    if (isItemShown || !isIntersecting) {
      // Bail early if already shown
      return;
    }
    tracking.sendEvent(trackingEvent);
    setIsItemShown(true);
  }, [isIntersecting, isItemShown]);

  const pickupDtps = dtpsFilterAvailable({
    dtps: openTJ.pickup_available_datetime_periods,
    isFlexibleDates: openTJ.is_flexible_dates,
  }) as DateTimePeriodOpenTj[];
  /**
   * Current DTP gets added to an OpenTJ when the view is grouped by day
   * This is needed in case of multiple DTPs:
   * - The day on the card is the same as the group day where it is listed
   * - We ensure that the time is shown belongs to the DTP of the group day (that can be different as well)
   */
  const pickupDtp = openTJ.currentDtp || pickupDtps[0];

  const hasMultipleAvailableDays = dtpsHaveDifferentDays([
    // We merge both pickup and delivery so we also mark TJs with different pickup and delivery dates as multiple
    ...openTJ.pickup_available_datetime_periods,
    ...openTJ.delivery_available_datetime_periods,
  ]);

  const shouldIndicateMultiDay =
    hasMultipleAvailableDays &&
    sortByField === "personalized" &&
    !openTJ.bundled &&
    openTJ.pickup_available_datetime_periods.length > 1;

  return (
    <div ref={intersectionRef}>
      <Card
        // NOTE: h-full ensures that the card is the same height as its sibling on the same row in the grid.
        className={cn("!mb-0", "cursor-pointer", {
          "lg:!border-blue-600": isActive,
          "radial-background relative": shouldIndicateMultiDay,
        })}
        onClick={() => {
          try {
            sessionStorage.setItem(PersistedStorageKey.BRENGER_LIST_JOB_SCORE, JSON.stringify(trackingEvent));
          } catch (e) {
            logger.dev(e);
          }
          history.push(Routes.new.job({ job_id: jobId }));
        }}
      >
        <TransportJobDetails
          from={formatLocality(openTJ.first_stop_address.locality, openTJ.first_stop_address.administrative_area)}
          // In the unlikely case of undefined, null is returned
          pickupPeriod={
            openTJ.first_pickup_datetime_period.period_start && openTJ.first_pickup_datetime_period.period_end
              ? formatTimeframe({
                  start: openTJ.first_pickup_datetime_period.period_start,
                  end: openTJ.first_pickup_datetime_period.period_end,
                })
              : undefined
          }
          to={formatLocality(openTJ.last_stop_address.locality, openTJ.last_stop_address.administrative_area)}
          // In the unlikely case of undefined, null is returned
          deliveryPeriod={
            openTJ.last_delivery_datetime_period.period_start && openTJ.last_delivery_datetime_period.period_end
              ? formatTimeframe({
                  start: openTJ.last_delivery_datetime_period.period_start,
                  end: openTJ.last_delivery_datetime_period.period_end,
                })
              : undefined
          }
          distance={openTJ.total_distance_km}
          price={openTJ.total_payout}
          isPrepaid={openTJ.directly_claimable}
          isBundled={openTJ.bundled}
          isComboJob={isComboJob(openTJ)}
          isBusiness={openTJ.business}
          day={pickupDtp && formatDateFull(pickupDtp.period_start)}
          stopCount={openTJ.stop_count}
          rewardTime={formatSecondsToHoursMinutes(openTJ.rewarded_working_time)}
          // Open TJ is only working with inflated values
          maxVolume={openTJ.max_volume_m3}
          // Show volume details rather than every single item when dealing with a bundle in this context.
          items={openTJ.bundled ? [] : [openTJ.largest_item]}
          services={openTJ.all_services}
          hasMultipleAvailableDays={hasMultipleAvailableDays}
          all_stop_details={openTJ.all_stop_details}
        />
        {shouldIndicateMultiDay && (
          <DayPagination dtps={openTJ.pickup_available_datetime_periods} current={openTJ.currentDtp} />
        )}
      </Card>
    </div>
  );
};
