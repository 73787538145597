import { DateTimePeriod, DateTimePeriodOpenTj } from "@brenger/api-client";
import { differenceInDays, isBefore, parseISO, startOfToday } from "date-fns";

type Dtp = DateTimePeriod | DateTimePeriodOpenTj;

interface DtpsFilterAvailable {
  dtps: Dtp[] | null;
  isFlexibleDates: boolean;
}

// small util so we can handle openTJ DTP and normal DTP
const getStartFromDtp = (dtp: Dtp): string => {
  return "period_start" in dtp ? dtp.period_start : dtp.start;
};
// simple util to sort dates
const sortDtps = (dtps: Dtp[]): Dtp[] =>
  dtps.filter(Boolean).sort((a, b) => parseISO(getStartFromDtp(a)).getTime() - parseISO(getStartFromDtp(b)).getTime());

export const dtpsFilterAvailable = ({ dtps, isFlexibleDates }: DtpsFilterAvailable): Dtp[] => {
  if (!dtps || !dtps.length) return [];
  // Make sure we have them in the right order
  const sortedDtps = sortDtps(dtps);

  // The two days we compare against
  const today = startOfToday();
  const lastOption = parseISO(getStartFromDtp(sortedDtps[sortedDtps.length - 1]));

  return sortedDtps.filter((dtp) => {
    const start = parseISO(getStartFromDtp(dtp));
    // Filter out any days in the past
    if (isBefore(start, today)) return false;

    // If normal TJ, then no further checks needed
    if (!isFlexibleDates) return true;

    // When flex:
    // We need to see if the DTP is three days ahead
    const diffDays = differenceInDays(start, today);
    if (diffDays >= 3) return true;

    // But we don't problematic rush situation, so if we approach the last option, we also let them claim
    const diffToLast = differenceInDays(lastOption, start);

    return [0, 1, 2].includes(diffToLast);
  });
};

interface DtpsFilterAvailableOpenTJ {
  dtps: DateTimePeriodOpenTj[] | null;
  isFlexibleDates: boolean;
  isBundled: boolean;
}

export const dtpsFilterAvailableOpenTJ = ({
  dtps,
  isFlexibleDates,
  isBundled,
}: DtpsFilterAvailableOpenTJ): DateTimePeriodOpenTj[] => {
  if (!dtps || !dtps.length) return [];
  if (!isBundled) return dtpsFilterAvailable({ dtps, isFlexibleDates }) as DateTimePeriodOpenTj[];
  return dtpsFilterForOverlap({ dtps });
};

export const dtpsFilterForOverlap = ({ dtps }: { dtps: DateTimePeriodOpenTj[] }): DateTimePeriodOpenTj[] => {
  const today = startOfToday();
  const sortedDtps = sortDtps(dtps);
  const uniqueStops = new Set<string>();
  const overlapDtps: Record<string, DateTimePeriodOpenTj[]> = {};

  (sortedDtps as DateTimePeriodOpenTj[]).forEach((dtp) => {
    // bail early on passed dates
    const date = parseISO(dtp.period_start);
    if (isBefore(date, today)) return;

    // First add stop id to set
    uniqueStops.add(dtp.stop_id);
    // Generate date key, we use it for storing the dtps
    const dateKey = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    // Create new or store in existing
    if (overlapDtps[dateKey]) {
      overlapDtps[dateKey].push(dtp);
    } else {
      overlapDtps[dateKey] = [dtp];
    }
  });

  // To pick the correct available ones, we compare amount of DTPs to unique stop count
  let availableDtps: DateTimePeriodOpenTj[] = [];
  Object.values(overlapDtps).forEach((dates) => {
    if (dates.length === uniqueStops.size) availableDtps = availableDtps.concat(dates);
  });

  return availableDtps;
};
